import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect, useState } from "react";
import { GoChevronDown, GoChevronUp, GoDownload } from "react-icons/go";
import { useParams } from "react-router-dom";
import { fetchTransactions } from "../controller"; // Ensure this import is correct
import { Transactions } from "../types";

const logoUrl = "../images/logo.png";

export function DownloadPDF() {
  const { accNo } = useParams(); // Get the account number from the URL parameters
  const [startDate, setStartDate] = useState<string>(new Date().toISOString().split("T")[0]); // Initialize with current date
  const [endDate, setEndDate] = useState<string>("");
  const [transactions, setTransactions] = useState<Transactions[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);

  useEffect(() => {
    async function loadTransactions() {
      if (accNo) {
        try {
          const { transactions, totalBalance } = await fetchTransactions(accNo, 0, 100); // Fetch initial transactions
          setTransactions(transactions);
        } catch (error) {
          console.error("Failed to fetch transactions:", error);
        }
      }
    }
    loadTransactions();
  }, [accNo]);

  const handleDownloadPDF = () => {
    if (!startDate || !endDate) {
      alert("Please select a date range.");
      return;
    }

    const filteredTransactions = transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date);
      return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
    });

    const { jsPDF } = require("jspdf");
    require("jspdf-autotable");

    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    const imgWidth = 30; // Width of the image
    const imgHeight = 20; // Height of the image
    const xPosition = 10; // Position for the logo on the top left

    // Add logo image on the top left
    doc.addImage(logoUrl, "PNG", xPosition, 20, imgWidth, imgHeight);

    // Add the header text on the right
    doc.setFontSize(24); // Set font size for VWT
    doc.setFont("helvetica", "bold"); // Set font to bold
    doc.text("VWT", pageWidth - 10, 30, { align: "right" });

    doc.setFontSize(16); // Set font size for STATEMENT
    doc.text("STATEMENT", pageWidth - 10, 40, { align: "right" });

    // Add user details on the left
    doc.setFontSize(10);
    doc.setFont("helvetica", "normal");
    doc.text("Random Address", 10, 50, { align: "left" });
    doc.text("Phone: 0796808579", 10, 60, { align: "left" });
    doc.text("Email: support@kberen.com", 10, 70, { align: "left" });

    // Add statement details on the right
    doc.text(`Date of Statement: ${new Date().toLocaleDateString()}`, pageWidth - 10, 50, { align: "right" });
    doc.text(
      `Statement Period: ${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`,
      pageWidth - 10,
      60,
      { align: "right" }
    );
    doc.text(`Customer ID: ${accNo}`, pageWidth - 10, 70, { align: "right" });

    doc.setFillColor(147, 197, 253);
    doc.rect(pageWidth - 60, 75, 50, 10, "F");
    doc.setTextColor(255, 255, 255);
    doc.text("Remittance Details", pageWidth - 55, 82);

    // Add balance
    doc.setTextColor(0, 0, 0);
    doc.text(`Balance: 147,197,253`, pageWidth - 10, 94, { align: "right" });

    // Add the transactions table
    doc.autoTable({
      startY: 110, // Start the table below the text
      head: [["Date", "Reference", "Initiator", "Description", "Status", "Credit", "Debit", "Balance"]],
      body: filteredTransactions.map(transaction => [
        transaction.date,
        transaction.reference,
        transaction.initiator,
        transaction.description,
        transaction.status,
        transaction.credit,
        transaction.debit,
        transaction.balance,
      ]),
      theme: "grid", // Optional: Add a grid theme for better readability
      headStyles: {
        fillColor: [147, 197, 253],
      },
      styles: { cellPadding: 3, fontSize: 10 },
    });

    doc.save("transactions.pdf");
  };

  return (
    <div className="relative">
      <button
        onClick={() => setShowDatePicker(!showDatePicker)}
        className="bg-blue-500 flex items-center rounded-lg p-2 px-4 text-white font-poppins font-bold text-sm flex-row gap-3 hover:bg-blue-600"
      >
        Select Date Range
        {showDatePicker ? <GoChevronUp size={25} /> : <GoChevronDown size={25} />}
      </button>
      {showDatePicker && (
        <div className="absolute right-0 mt-2 w-auto p-3 bg-white border rounded-md shadow-lg">
          <div className="flex flex-row mb-2 gap-4">
            <label className="whitespace-nowrap text-sm">Start Date</label>
            <input
              type="date"
              className="border w-auto text-center text-sm border-gray-400"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
            />
          </div>
          <div className="flex flex-row mb-2 gap-4">
            <label className="whitespace-nowrap text-sm">End Date</label>
            <input
              type="date"
              className="border w-auto text-center border-gray-400"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
            />
          </div>
          <button
            onClick={handleDownloadPDF}
            className="bg-blue-400 flex items-center rounded-lg p-2 px-4 text-white font-poppins font-bold text-sm flex-row gap-3 hover:bg-green-600"
          >
            Download PDF
            <GoDownload size={25} />
          </button>
        </div>
      )}
    </div>
  );
}
