import React from "react";
import { HiOutlineSearch } from "react-icons/hi"; // Search Icon
import { MdClose } from "react-icons/md"; // Close Icon

interface SearchBarProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

export function SearchBar({ searchText, setSearchText }: SearchBarProps) {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const clearSearch = () => {
    setSearchText("");
  };

  return (
    <div className="relative flex items-center w-2/3 md:w-1/3 max-w-md">
      {/* Search Icon */}
      <HiOutlineSearch className="absolute left-3 text-gray-500 text-xl" />

      {/* Search Input */}
      <input
        type="text"
        value={searchText}
        onChange={handleInputChange}
        placeholder="Search..."
        className="w-full pl-10 pr-10 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
      />

      {/* Conditionally Rendered Close Icon */}
      {searchText && (
        <button onClick={clearSearch} className="absolute right-3 text-gray-500 hover:text-red-500">
          <MdClose className="text-xl" />
        </button>
      )}
    </div>
  );
}
