import { AccviewTable } from "../view/accview-table";

const LogoImage = "../images/logo.png";

export function AccountOverview() {
  return (
    <div className="flex flex-col">
      <div className="justify-between flex-row flex">
        <div className=" top-0 left-0 p-4 md:ml-2 ">
          <img src={LogoImage} alt="Logo" className="w-25 h-20 object-contain" />
        </div>
        <div className="flex flex-row justify-center items-center mr-10">
          <img className="rounded-full h-10 w-10 bg-blue-300" />
          <p className="font-poppins text-lg ml-3 ">Yvonne Mwikali</p>
        </div>
      </div>
      <h1 className="text-center  font-poppins font-bold text-xl text-blue-900">Account Overview</h1>
      <div className="mx-10 ">
        <AccviewTable />
      </div>
    </div>
  );
}
