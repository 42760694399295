import { useEffect, useState } from "react";
import { BsBank } from "react-icons/bs";
import { dummyBanks } from "../../data";
import { Bank, UIPaymentActions } from "../../types";
import { Modal } from "../common";
import { SearchBar } from "../common/search-bar";
import { CardListItem } from "./card-list-items";
import { FullLengthBtn } from "./full-length-btn";
import { PaymentOptionsListHeader } from "./payment-options-list-header";
import { SupportedBankItemActions } from "./supported-bank-item-actions";
import { SupportedBankDetails } from "./supported-bank-details";

export function SupportedBankList() {
  const [modalContent, setModalContent] = useState<UIPaymentActions | undefined>(undefined);
  const [title, setTitle] = useState("");
  const [toggleMsg, setToggleMsg] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedBank, setSelectedBank] = useState<Bank | undefined>(undefined);
  const [filteredBanks, setFilteredBanks] = useState<Bank[]>(dummyBanks);

  const leftIcon = <BsBank className="text-blue-500 text-2xl mr-4" />;

  const onClose = () => {
    setModalContent(undefined);
    setSelectedBank(undefined);
  };

  useEffect(() => {
    if (!!searchText) setFilteredBanks(filterBanks(dummyBanks, searchText));
  }, [searchText]);

  const onAddNewBank = () => {
    setTitle("Add new bank");
    setModalContent("form");
  };

  let contentBody = <></>;
  if (modalContent === "details") contentBody = selectedBank ? <SupportedBankDetails bank={selectedBank} /> : <></>;
  else if (modalContent === "form") contentBody = <></>;
  else if (modalContent === "toggle") contentBody = <p>{toggleMsg}</p>;

  const results = !!searchText ? filteredBanks : dummyBanks;

  return (
    <>
      <div className="rounded-lg shadow w-11/12 h-5/6 flex flex-col overflow-hidden bg-white bg-opacity-80 backdrop-blur-md pt-4">
        {/* Card Title */}
        <PaymentOptionsListHeader label="Supported banks" setShowSearch={setShowSearch} showSearch={showSearch} />

        {showSearch && (
          <div className="flex w-full justify-end pr-12">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
          </div>
        )}

        {/* Scrollable list of dummy cards */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 custom-scrollbar px-8">
          {/* Dummy bank cards */}
          {results.map(bank => (
            <CardListItem
              key={bank.id}
              id={bank.id}
              title={bank.bankName}
              subTitle={bank.countryCode}
              leftIcon={leftIcon}
              actions={
                <SupportedBankItemActions
                  bank={bank}
                  setModalContent={setModalContent}
                  setTitle={setTitle}
                  setToggleMsg={setToggleMsg}
                  setSelectedBank={setSelectedBank}
                />
              }
            />
          ))}
        </div>

        {/* Button at the bottom */}
        <FullLengthBtn label="Add new supported bank" onClick={onAddNewBank} />
      </div>
      {!!modalContent && (
        <Modal
          title={title}
          cancelText={modalContent === "details" ? "Close" : "Cancel"}
          proceedText={modalContent === "details" ? "" : "Confirm"}
          contentBody={contentBody}
          onProceed={() => {}}
          onClose={onClose}
          isVisible={!!modalContent}
        />
      )}
    </>
  );
}

function filterBanks(banks: Bank[], searchText: string): Bank[] {
  const lowerCaseSearchText = searchText.toLowerCase().trim();

  return banks.filter(currency => currency.bankName.toLowerCase().startsWith(lowerCaseSearchText));
}
