import { useState } from "react";
import * as PaymentComponents from "../components/payment-options";
import { PaymentOptionValues } from "../types";

export function PaymentOptions() {
  const [selectedOption, setSelectedOption] = useState<PaymentOptionValues>("supportedBanks");

  return (
    <div className="relative min-h-screen bg-gray-100">
      <div className="absolute top-0 left-0 w-96 h-96 bg-[#2563EB] opacity-50 rounded-tr-none rounded-br-full rounded-tl-none rounded-bl-none"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-[#2563EB] opacity-70 rounded-tr-none rounded-br-none rounded-tl-full rounded-bl-none"></div>
      <div className="w-full h-screen flex">
        {/* First half - Hidden on mobile */}
        <div className="hidden md:flex w-2/5  justify-center items-center">
          <div className="bg-white bg-opacity-50 backdrop-blur-md rounded-lg p-8 shadow-lg">
            <PaymentComponents.PaymentOptionsList selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
          </div>
        </div>

        {/* Second half */}
        <div className="w-full md:w-3/5 flex justify-center items-center">
          {selectedOption === "supportedBanks" && <PaymentComponents.SupportedBankList />}
          {selectedOption === "supportedCurrencies" && <PaymentComponents.SupportedCurrenciesList />}
          {selectedOption === "supportedPaymentMethods" && <PaymentComponents.SupportedPaymentMethodsList />}
        </div>
      </div>
    </div>
  );
}
