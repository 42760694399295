import { useState, useEffect, ChangeEvent, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { fetchTransactions } from "../controller";
import { transactionColumns } from "../utils";
import { SearchButton } from "../components";
import { TransactionTableFilter, Transactions } from "../types";
import { TransactionFilterButton } from "../components/transaction-filterbutton";

export function TransactionsTable() {
  const { accNo } = useParams<{ accNo: string }>(); // Get the account number from the URL parameters
  const [transactions, setTransactions] = useState<Transactions[]>([]);
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0); // Current page
  const [pageSize] = useState<number>(10); // Page size (number of rows per page)
  const [totalRecords, setTotalRecords] = useState<number>(0); // Total number of records

  // State to hold the selected filters
  const [filterOptions, setFilterOptions] = useState<TransactionTableFilter | undefined>(undefined);

  // Fetch transactions whenever account number, page, pageSize, or filterOptions change
  useEffect(() => {
    async function loadTransactions() {
      if (accNo) {
        try {
          setLoading(true);
          const { transactions, totalBalance, totalRecords } = await fetchTransactions(
            accNo,
            page,
            pageSize,
            filterOptions // Pass the filter options to the API
          );
          setTransactions(transactions);
          setTotalBalance(totalBalance);
          setTotalRecords(totalRecords); // Set total records for pagination
        } catch (error) {
          console.error("Failed to fetch transactions:", error);
        } finally {
          setLoading(false);
        }
      }
    }
    loadTransactions();
  }, [accNo, page, pageSize, filterOptions]); // Re-fetch data whenever these dependencies change

  const columns = useMemo(() => transactionColumns, []);
  const data = useMemo(() => transactions, [transactions]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    preGlobalFilteredRows,
    globalFilter,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true, // Enable server-side pagination
      pageCount: Math.ceil(totalRecords / pageSize), // Calculate page count based on total records
    },
    useGlobalFilter,
    usePagination
  );

  // Handle the filter change and update the state with the selected filter
  const handleFilterChange = (selectedFilter: TransactionTableFilter) => {
    setFilterOptions(selectedFilter); // Update filter state
    setPage(0); // Reset to the first page when a filter is applied
  };

  const handleNextPage = () => setPage(prev => prev + 1);
  const handlePreviousPage = () => setPage(prev => (prev > 0 ? prev - 1 : 0));

  if (!accNo) {
    return <div>Account number not provided</div>;
  }

  if (loading) {
    return <div>Loading transactions...</div>;
  }

  return (
    <>
      <div>
        <div className="items-center flex  flex-col">
          <h2 className="text-center text-xl mb-4 font-semibold">Transactions for Account {accNo}</h2>
          <div className="text-center items-center flex flex-row bg-blue-200 p-2 rounded-md shadow-md">
            <p className=" font-poppins font-medium mr-4 text-lg">Balance : </p>
            <p className="font-bold text-lg text-black">{totalBalance}</p>
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          {/* Filter Button */}
          <TransactionFilterButton onClick={handleFilterChange} />

          {/* Global Search Filter */}
          <SearchButton
            onChange={(e: ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
            value={globalFilter}
            placeholder={`Search ${preGlobalFilteredRows.length} records...`}
          />
        </div>
      </div>

      <table {...getTableProps()} className="table-auto w-full">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()} className="px-4 py-2 border bg-blue-300">
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-300">
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-4 py-2 mb-10 items-center text-center align-middle justify-center border-b cursor-pointer"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination */}
      <div className="flex flex-row justify-between w-full mt-4">
        <button onClick={handlePreviousPage} disabled={page === 0} className="px-4 py-2 border">
          Previous
        </button>
        <span className="px-4 py-2">
          Page {page + 1} of {Math.ceil(totalRecords / pageSize)}
        </span>
        <button onClick={handleNextPage} disabled={(page + 1) * pageSize >= totalRecords} className="px-4 py-2 border">
          Next
        </button>
      </div>
    </>
  );
}
