import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { Landing } from "./components";
import { AccountOverview } from "./pages/account-overview";
import { TransactionsOverview } from "./pages/transaction-overview";
import { PaymentOptions } from "./pages";

const router = createBrowserRouter([
  { path: "/", element: <Landing /> },
  { path: "accounts", element: <AccountOverview /> },
  { path: "accounts/:accNo", element: <TransactionsOverview /> },
  { path: "/payment-options", element: <PaymentOptions /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
