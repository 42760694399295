import React from "react";

interface CardListItemProps {
  id: string;
  title: string;
  leftIcon: React.ReactNode;
  actions?: React.ReactNode;
  subTitle?: string;
}

export function CardListItem(props: CardListItemProps) {
  const { id, title, actions, subTitle, leftIcon } = props;
  return (
    <div key={id} className="flex items-center justify-between p-4 border rounded shadow bg-white bg-opacity-80">
      {/* Icon and Information */}
      <div className="flex items-center">
        {/* Left Icon */}
        {leftIcon}
        <div>
          {/* Title */}
          <div className="font-normal text-lg">{title}</div>
          {/* Subtitle */}
          {subTitle && <div className="text-gray-600 text-sm">{subTitle}</div>}
        </div>
      </div>

      {/* Action Buttons */}
      {actions && <div className="flex items-center space-x-1">{actions}</div>}
    </div>
  );
}
