import { IoIosAdd } from "react-icons/io";

interface FullLengthBtnProps {
  onClick: () => void;
  label: string;
}

export function FullLengthBtn({ label, onClick }: FullLengthBtnProps) {
  return (
    <button onClick={onClick} className="bg-blue-500 text-white w-full hover:bg-blue-600  py-4 flex items-center justify-center mt-auto">
      <IoIosAdd className="text-3xl mr-2 text-white" />
      {label}
    </button>
  );
}
