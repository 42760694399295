import { Currency } from "../types";

export const dummyCurrencies: Currency[] = [
    {
        id: "9a1b2c3d-1234-5678-9101-abcdefabcdef",
        virtualWallets: [],
        currencyName: "Kenyan Shilling",
        currencyCode: "KES",
        countryCode: "KE",
        countryName: "Kenya",
        countryDialCode: "+254",
        supportedCurrency: null,
        isActive: true,
    },
    {
        id: "9b2c3d4e-2345-6789-1011-bcdefabcdefa",
        virtualWallets: [],
        currencyName: "United States Dollar",
        currencyCode: "USD",
        countryCode: "US",
        countryName: "United States",
        countryDialCode: "+1",
        supportedCurrency: null,
        isActive: true,
    },
    {
        id: "9c3d4e5f-3456-7890-1122-cdefabcdefab",
        virtualWallets: [],
        currencyName: "Euro",
        currencyCode: "EUR",
        countryCode: "DE",
        countryName: "Germany",
        countryDialCode: "+49",
        supportedCurrency: null,
        isActive: true,
    },
    {
        id: "9d4e5f6g-4567-8901-1233-defabcdefabc",
        virtualWallets: [],
        currencyName: "British Pound",
        currencyCode: "GBP",
        countryCode: "GB",
        countryName: "United Kingdom",
        countryDialCode: "+44",
        supportedCurrency: null,
        isActive: false,
    },
    {
        id: "9e5f6g7h-5678-9012-1344-efabcdefabcd",
        virtualWallets: [],
        currencyName: "Canadian Dollar",
        currencyCode: "CAD",
        countryCode: "CA",
        countryName: "Canada",
        countryDialCode: "+1",
        supportedCurrency: null,
        isActive: true,
    },
    {
        id: "9f6g7h8i-6789-0123-1455-fabcdefabcde",
        virtualWallets: [],
        currencyName: "Australian Dollar",
        currencyCode: "AUD",
        countryCode: "AU",
        countryName: "Australia",
        countryDialCode: "+61",
        supportedCurrency: null,
        isActive: true,
    },
    {
        id: "9g7h8i9j-7890-1234-1566-abcdefabcdef",
        virtualWallets: [],
        currencyName: "Indian Rupee",
        currencyCode: "INR",
        countryCode: "IN",
        countryName: "India",
        countryDialCode: "+91",
        supportedCurrency: null,
        isActive: true,
    },
    {
        id: "9h8i9j1k-8901-2345-1677-bcdefabcdefb",
        virtualWallets: [],
        currencyName: "South African Rand",
        currencyCode: "ZAR",
        countryCode: "ZA",
        countryName: "South Africa",
        countryDialCode: "+27",
        supportedCurrency: null,
        isActive: true,
    }
];
