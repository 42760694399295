import { Column } from "react-table";
import { Transactions, TransactionsList } from "../types";

export const transactionData: TransactionsList = {
  "User: John Doe": [
    {
      date: "2023-09-01",
      reference: "TX1234",
      initiator: "John Doe",
      initiatorNo: "001",
      description: "Salary",
      status: "Completed",
      credit: 5000,
      debit: 0,
      balance: 5000,
    },
    {
      date: "2023-09-05",
      reference: "TX1235",
      initiator: "John Doe",
      initiatorNo: "001",
      description: "Rent Payment",
      status: "Completed",
      credit: 0,
      debit: 1500,
      balance: 3500,
    },
    {
      date: "2023-09-10",
      reference: "TX1236",
      initiator: "John Doe",
      initiatorNo: "001",
      description: "Utility Bill",
      status: "Completed",
      credit: 0,
      debit: 200,
      balance: 3300,
    },
  ],
  "RentHub Building: 123 Main St": [
    {
      date: "2023-08-22",
      reference: "TX2001",
      initiator: "Tenant A",
      initiatorNo: "002",
      description: "Rent Payment",
      status: "Completed",
      credit: 1800,
      debit: 0,
      balance: 1800,
    },
    {
      date: "2023-08-23",
      reference: "TX2002",
      initiator: "Maintenance Fee",
      initiatorNo: "002",
      description: "Building Repairs",
      status: "Completed",
      credit: 0,
      debit: 300,
      balance: 1500,
    },
    {
      date: "2023-08-25",
      reference: "TX2003",
      initiator: "Utility Bill",
      initiatorNo: "002",
      description: "Water Bill",
      status: "Completed",
      credit: 0,
      debit: 100,
      balance: 1400,
    },
    {
      date: "2023-08-28",
      reference: "TX2004",
      initiator: "Tenant B",
      initiatorNo: "003",
      description: "Rent Payment",
      status: "Completed",
      credit: 2200,
      debit: 0,
      balance: 3600,
    },
    {
      date: "2023-08-30",
      reference: "TX2005",
      initiator: "Utility Bill",
      initiatorNo: "002",
      description: "Electricity Bill",
      status: "Completed",
      credit: 0,
      debit: 150,
      balance: 3450,
    },
  ],
  "User: Alice Smith": [
    {
      date: "2023-07-15",
      reference: "TX3001",
      initiator: "Alice Smith",
      initiatorNo: "003",
      description: "Rent Payment",
      status: "Completed",
      credit: 2500,
      debit: 0,
      balance: 2500,
    },
    {
      date: "2023-07-18",
      reference: "TX3002",
      initiator: "Utility Bill",
      initiatorNo: "003",
      description: "Water Bill",
      status: "Completed",
      credit: 0,
      debit: 150,
      balance: 2350,
    },
  ],
  "Company: Johnson Realty": [
    {
      date: "2023-06-10",
      reference: "TX4001",
      initiator: "Johnson Realty",
      initiatorNo: "004",
      description: "Property Sale",
      status: "Completed",
      credit: 10000,
      debit: 0,
      balance: 10000,
    },
    {
      date: "2023-06-12",
      reference: "TX4002",
      initiator: "Utility Bill",
      initiatorNo: "004",
      description: "Electricity Bill",
      status: "Completed",
      credit: 0,
      debit: 300,
      balance: 9700,
    },
    {
      date: "2023-06-15",
      reference: "TX4003",
      initiator: "Maintenance Fee",
      initiatorNo: "004",
      description: "Building Maintenance",
      status: "Completed",
      credit: 0,
      debit: 500,
      balance: 9200,
    },
    {
      date: "2023-06-18",
      reference: "TX4004",
      initiator: "Property Manager",
      initiatorNo: "004",
      description: "Rent Payment",
      status: "Completed",
      credit: 3000,
      debit: 0,
      balance: 12200,
    },
    {
      date: "2023-06-20",
      reference: "TX4005",
      initiator: "Utility Bill",
      initiatorNo: "004",
      description: "Water Bill",
      status: "Completed",
      credit: 0,
      debit: 150,
      balance: 12050,
    },
    {
      date: "2023-06-22",
      reference: "TX4006",
      initiator: "Maintenance Fee",
      initiatorNo: "004",
      description: "Landscaping",
      status: "Completed",
      credit: 0,
      debit: 200,
      balance: 11850,
    },
    {
      date: "2023-06-25",
      reference: "TX4007",
      initiator: "Utility Bill",
      initiatorNo: "004",
      description: "Gas Bill",
      status: "Completed",
      credit: 0,
      debit: 100,
      balance: 11750,
    },
  ],
  "User: Charlie Brown": [
    {
      date: "2023-05-08",
      reference: "TX5001",
      initiator: "Charlie Brown",
      initiatorNo: "005",
      description: "Rent Payment",
      status: "Completed",
      credit: 2200,
      debit: 0,
      balance: 2200,
    },
    {
      date: "2023-05-10",
      reference: "TX5002",
      initiator: "Utility Bill",
      initiatorNo: "005",
      description: "Gas Bill",
      status: "Completed",
      credit: 0,
      debit: 120,
      balance: 2080,
    },
    {
      date: "2023-05-12",
      reference: "TX5003",
      initiator: "Utility Bill",
      initiatorNo: "005",
      description: "Water Bill",
      status: "Completed",
      credit: 0,
      debit: 100,
      balance: 1980,
    },
    {
      date: "2023-05-15",
      reference: "TX5004",
      initiator: "Utility Bill",
      initiatorNo: "005",
      description: "Electricity Bill",
      status: "Completed",
      credit: 0,
      debit: 150,
      balance: 1830,
    },
    {
      date: "2023-05-18",
      reference: "TX5005",
      initiator: "Maintenance Fee",
      initiatorNo: "005",
      description: "Building Repairs",
      status: "Completed",
      credit: 0,
      debit: 200,
      balance: 1630,
    },
    {
      date: "2023-05-20",
      reference: "TX5006",
      initiator: "Utility Bill",
      initiatorNo: "005",
      description: "Internet Bill",
      status: "Completed",
      credit: 0,
      debit: 50,
      balance: 1580,
    },
  ],
  // Continue adding similar transaction data for other owners...
};

export const transactionColumns: Column<Transactions>[] = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Reference",
    accessor: "reference",
  },
  {
    Header: "Initiator",
    accessor: "initiator",
  },
  {
    Header: "Initiator No.",
    accessor: "initiatorNo",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Credit",
    accessor: "credit",
  },
  {
    Header: "Debit",
    accessor: "debit",
  },
  {
    Header: "Balance",
    accessor: "balance",
  },
];
