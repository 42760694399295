import { useEffect, useState } from "react";
import { MdPayment } from "react-icons/md";
import { dummyPaymentMethods } from "../../data";
import { Modal } from "../common";
import { CardListItem } from "./card-list-items";
import { FullLengthBtn } from "./full-length-btn";
import { PaymentMethod, UIPaymentActions } from "../../types";
import { PaymentOptionsListHeader } from "./payment-options-list-header";
import { SearchBar } from "../common/search-bar";
import { SupportedPaymentMethodsActions } from "./supported-payment-methods-actions";
import { SupportedPaymentMethodsDetails } from "./supported-payment-methods-details";

export function SupportedPaymentMethodsList() {
  const [modalContent, setModalContent] = useState<UIPaymentActions | undefined>(undefined);
  const [title, setTitle] = useState("");
  const [toggleMsg, setToggleMsg] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedPaymentService, setSelectedPaymentService] = useState<PaymentMethod | undefined>(undefined);
  const [filteredPaymentServices, setFilteredPaymentServices] = useState<PaymentMethod[]>(dummyPaymentMethods);

  const leftIcon = <MdPayment className="text-blue-500 text-2xl mr-4" />;

  useEffect(() => {
    if (!!searchText) setFilteredPaymentServices(filterPaymentServices(dummyPaymentMethods, searchText));
  }, [searchText]);

  const onClose = () => {
    setModalContent(undefined);
    setSelectedPaymentService(undefined);
  };

  const onAddPaymentService = () => {
    setTitle("Add new payment service");
    setModalContent("form");
  };

  let contentBody = <></>;
  if (modalContent === "details")
    contentBody = selectedPaymentService ? <SupportedPaymentMethodsDetails paymentMethod={selectedPaymentService} /> : <></>;
  else if (modalContent === "form") contentBody = <></>;
  else if (modalContent === "toggle") contentBody = <p>{toggleMsg}</p>;

  const results = !!searchText ? filteredPaymentServices : dummyPaymentMethods;

  return (
    <>
      <div className="rounded-lg shadow w-11/12 h-5/6 flex flex-col overflow-hidden bg-white bg-opacity-80 backdrop-blur-md pt-4">
        {/* Card Title */}
        <PaymentOptionsListHeader label="Supported payment services" setShowSearch={setShowSearch} showSearch={showSearch} />

        {showSearch && (
          <div className="flex w-full justify-end pr-12">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
          </div>
        )}

        {/* Scrollable list of dummy cards */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 custom-scrollbar px-8">
          {results.map(paymentService => (
            <CardListItem
              key={paymentService.id}
              id={paymentService.id}
              title={paymentService.serviceName}
              subTitle={paymentService.countryName}
              leftIcon={leftIcon}
              actions={
                <SupportedPaymentMethodsActions
                  paymentOption={paymentService}
                  setModalContent={setModalContent}
                  setTitle={setTitle}
                  setToggleMsg={setToggleMsg}
                  setSelectedPaymentService={setSelectedPaymentService}
                />
              }
            />
          ))}
        </div>

        {/* Button at the bottom */}
        <FullLengthBtn label="Add new supported payment option" onClick={onAddPaymentService} />
      </div>
      {!!modalContent && (
        <Modal
          title={title}
          cancelText={modalContent === "details" ? "Close" : "Cancel"}
          proceedText={modalContent === "details" ? "" : "Confirm"}
          contentBody={contentBody}
          onProceed={() => {}}
          onClose={onClose}
          isVisible={!!modalContent}
        />
      )}
    </>
  );
}

function filterPaymentServices(currencies: PaymentMethod[], searchText: string): PaymentMethod[] {
  const lowerCaseSearchText = searchText.toLowerCase().trim();

  return currencies.filter(currency => currency.serviceName.toLowerCase().startsWith(lowerCaseSearchText));
}
