import { CiFlag1 } from "react-icons/ci";
import { Bank } from "../../types";
import { DetailsListItem } from "./details-list-item";
import { CiCircleCheck } from "react-icons/ci";
import { BsBank } from "react-icons/bs";
import { IoIosBarcode } from "react-icons/io";

export function SupportedBankDetails({ bank }: { bank: Bank }) {
  const activeStatus = bank.isActive ? "Active" : "Inactive";

  return (
    <div>
      <DetailsListItem icon={<BsBank className="text-blue-500 text-2xl" />} label="Bank name" value={bank.bankName} />
      <DetailsListItem icon={<IoIosBarcode className="text-blue-500 text-2xl" />} label="Bank code" value={bank.bankCode} />
      <DetailsListItem icon={<CiFlag1 className="text-blue-500 text-2xl" />} label="Country code" value={bank.countryCode} />
      <DetailsListItem icon={<CiCircleCheck className="text-blue-500 text-2xl" />} label="Status" value={activeStatus} />
    </div>
  );
}
