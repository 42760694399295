import { ChangeEvent, useState } from "react";
import { CiSearch } from "react-icons/ci";

interface SearchInterface {
  //   onSearch: (item: string) => void;
  placeholder?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export function SearchButton({ placeholder = "search..", onChange, value }: SearchInterface) {
  const [searchQuery, setSearchQuery] = useState(placeholder);
  const handleSearch = () => {
    // onSearch(searchQuery);
  };

  return (
    <div>
      <button className="flex flex-row border-slate-600 p-8	">
        <input
          className="text-start font-medium text-sm ml-5 border-gray-600"
          placeholder={placeholder}
          type="text"
          onChange={onChange}
          value={value}
        />
        <CiSearch className="text-2xl ml-2 " />
      </button>
    </div>
  );
}
