import { useEffect, useState } from "react";
import { fetchData, updateFilter } from "../controller/fetch-data"; // Import your data functions
import { RowData, data as total } from "../utils";
import { BsFilterSquare } from "react-icons/bs";
import { FilterCondition, FilterType, TableFilter } from "../types";

const currencyTypes = total.map(currency => currency.currency);
const uniqueCurrencyTypes = currencyTypes.filter((name, index) => currencyTypes.indexOf(name) === index);

export function FilterButton({ onClick }: { onClick: (item: TableFilter) => void }) {
  const [showFilters, setShowFilters] = useState(false);
  const [filterType, setFilterType] = useState<FilterType>("currency"); // "bank" or "date"
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [dateCondition, setDateCondition] = useState<FilterCondition>("on"); // "on", "before", "after"
  const [filterDate, setFilterDate] = useState("");

  // State for storing fetched data, filtered data, and pagination
  const [data, setData] = useState<RowData[]>([]);
  const [filteredData, setFilteredData] = useState<RowData[]>([]);
  const [pageIndex, setPageIndex] = useState(0); // Current page index
  const [pageSize, setPageSize] = useState(10); // Number of rows per page

  // Fetch initial data when the component mounts
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const result = await fetchData(pageIndex, pageSize);
        setData(result.rows); // Set fetched data
        setFilteredData(result.rows); // Set filtered data initially to the same as fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchInitialData();
  }, [pageIndex, pageSize]); // Re-fetch data when pageIndex or pageSize changes

  // Toggle filter section visibility
  const toggleFilters = () => setShowFilters(!showFilters);

  const onSubmit = () => {
    console.log(filterType, selectedCurrency);
    if (filterType === "currency" && selectedCurrency) {
      onClick({ filter: selectedCurrency, type: filterType });
    } else if (filterType === "date" && filterDate) {
      console.log(filterDate);
      onClick({ filter: filterDate, type: filterType, condition: dateCondition });
    } else {
      // show an alert
    }
  };

  return (
    <div className="flex flex-col m-4 items-start">
      {/* Filter Button */}
      <button
        onClick={toggleFilters}
        className="flex ml-2 flex-row justify-between items-center gap-4 space-x-3 bg-gray-200 text-md font-semibold px-4 py-2 rounded-md shadow hover:bg-gray-300 transition duration-300"
      >
        Filter
        <BsFilterSquare className="text-blue-400" />
      </button>

      {/* Filter Section */}
      {showFilters && (
        <div className="flex flex-row ml-2 space-x-4 my-2 p-4 bg-white rounded-md shadow-md">
          <div>
            <label className="text-md font-semibold text-black">
              Filter By:
              <select
                value={filterType}
                onChange={e => setFilterType(e.target.value as FilterType)}
                className="border-b border-gray-300 font-medium focus:outline-none focus:border-blue-400 text-md"
              >
                <option value="">Select Filter</option>
                <option value="currency">Currency</option>
                <option value="date">Date</option>
              </select>
            </label>
          </div>

          {/* Filter by Bank */}
          {filterType === "currency" && (
            <div>
              <label className="text-md font-semibold text-black">
                currency:
                <select
                  value={selectedCurrency}
                  onChange={e => setSelectedCurrency(e.target.value)}
                  className="border-b border-gray-300 focus:outline-none font-medium focus:border-blue-400 text-md"
                >
                  <option value="">Currency</option>
                  {uniqueCurrencyTypes.map((bank, index) => (
                    <option key={index} value={bank}>
                      {bank}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}

          {/* Filter by Date */}
          {filterType === "date" && (
            <div className="flex flex-row items-center space-x-2">
              <label className="text-md font-semibold text-black">
                Date Condition:
                <select
                  value={dateCondition}
                  onChange={e => setDateCondition(e.target.value as FilterCondition)}
                  className="border-b border-gray-300 focus:outline-none font-medium focus:border-blue-400 text-md"
                >
                  <option value="">Select Condition</option>
                  <option value="on">On</option>
                  <option value="before">Before</option>
                  <option value="after">After</option>
                </select>
              </label>
              <input
                type="date"
                value={filterDate}
                onChange={e => setFilterDate(e.target.value)}
                className="border-b border-gray-300 focus:outline-none font-normal focus:border-blue-400 text-sm"
              />
            </div>
          )}

          {/* Apply Filters Button */}
          <button
            onClick={onSubmit}
            className="bg-green-300 text-white font-semibold py-1 px-1 rounded-md shadow hover:bg-green-500 transition duration-300"
          >
            submit
          </button>
        </div>
      )}
    </div>
  );
}
