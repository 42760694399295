import React from "react";

interface ModalProps {
  title: string;
  contentBody: React.ReactNode;
  isVisible: boolean;
  onClose: () => void;
  onProceed: () => void;
  proceedText: string;
  cancelText: string;
  isDismissible?: boolean; // New prop to control overlay click behavior
}

export function Modal(props: ModalProps) {
  const { isVisible, onClose, isDismissible = true, title } = props;
  const { contentBody, onProceed, proceedText, cancelText } = props;

  return (
    <div className="flex justify-center items-center h-screen">
      {/* Modal Overlay */}
      {isVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={isDismissible ? onClose : undefined} // Dismiss if allowed
        >
          {/* Modal Content */}
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-11/12 md:w-1/3"
            onClick={e => e.stopPropagation()} // Prevent click on modal content from closing it
          >
            <h2 className="text-xl font-normal my-4 mb-4 ml-4">{title}</h2>
            <div className="my-4">{contentBody}</div>

            {/* Modal Actions */}
            <div className="flex justify-end gap-4">
              {cancelText && (
                <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded mr-2">
                  {cancelText}
                </button>
              )}
              {proceedText && (
                <button onClick={onProceed} className="bg-blue-600 text-white px-4 py-2 rounded">
                  {proceedText}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
