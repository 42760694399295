import { Bank } from "../types";

export const dummyBanks: Bank[] = [
    {
        id: "1a2b3c4d-1234-5678-9101-abcdefabcdef",
        bankName: "National Bank",
        bankCode: "NB123",
        countryCode: "KE",
        externalWallets: [],
        isActive: true
    },
    {
        id: "1b2c3d4e-2345-6789-1011-bcdefabcdefa",
        bankName: "Equity Bank",
        bankCode: "EQ456",
        countryCode: "KE",
        externalWallets: [],
        isActive: true,
    },
    {
        id: "1c2d3e4f-3456-7890-1122-cdefabcdefab",
        bankName: "Co-operative Bank",
        bankCode: "CO789",
        countryCode: "KE",
        externalWallets: [],
        isActive: false
    },
    {
        id: "1d2e3f4g-4567-8901-1233-defabcdefabc",
        bankName: "KCB Bank",
        bankCode: "KCB101",
        countryCode: "KE",
        externalWallets: [],
        isActive: true
    },
    {
        id: "1e2f3g4h-5678-9012-1344-efabcdefabcd",
        bankName: "Standard Chartered",
        bankCode: "SC202",
        countryCode: "KE",
        externalWallets: [],
        isActive: true
    },
    {
        id: "1f2g3h4i-6789-0123-1455-fabcdefabcde",
        bankName: "Barclays Bank",
        bankCode: "BB303",
        countryCode: "KE",
        externalWallets: [],
        isActive: true
    },
    {
        id: "1g2h3i4j-7890-1234-1566-abcdefabcdef",
        bankName: "DTB Bank",
        bankCode: "DT404",
        countryCode: "KE",
        externalWallets: [],
        isActive: true
    },
    {
        id: "1h2i3j4k-8901-2345-1677-bcdefabcdefb",
        bankName: "Family Bank",
        bankCode: "FB505",
        countryCode: "KE",
        externalWallets: [],
        isActive: false
    }
];
