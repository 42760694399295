import { CiCircleCheck, CiFlag1, CiGlobe, CiPhone } from "react-icons/ci";
import { GrCurrency } from "react-icons/gr";
import { IoIosBarcode } from "react-icons/io";
import { Currency } from "../../types";
import { DetailsListItem } from "./details-list-item";

export function SupportedCurrencyDetails({ currency }: { currency: Currency }) {
  const activeStatus = currency.isActive ? "Active" : "Inactive";

  return (
    <div>
      <DetailsListItem icon={<GrCurrency className="text-blue-500 text-2xl" />} label="Currency name" value={currency.currencyName} />
      <DetailsListItem icon={<IoIosBarcode className="text-blue-500 text-2xl" />} label="Currency code" value={currency.currencyCode} />
      <DetailsListItem icon={<CiGlobe className="text-blue-500 text-2xl" />} label="Country name" value={currency.countryName} />
      <DetailsListItem icon={<CiFlag1 className="text-blue-500 text-2xl" />} label="Country code" value={currency.countryCode} />
      <DetailsListItem icon={<CiPhone className="text-blue-500 text-2xl" />} label="Country dial code" value={currency.countryDialCode} />
      <DetailsListItem icon={<CiCircleCheck className="text-blue-500 text-2xl" />} label="Status" value={activeStatus} />
    </div>
  );
}
