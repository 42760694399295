import { PaymentMethod } from "../types";

export const dummyPaymentMethods: PaymentMethod[] = [
    {
        id: "1",
        serviceName: "M-Pesa",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: true,
        rates: [
            { minAmount: 1, maxAmount: 1000, rate: 10 },
            { minAmount: 1001, maxAmount: 5000, rate: 15 },
            { minAmount: 5001, maxAmount: 10000, rate: 20 }
        ]
    },
    {
        id: "2",
        serviceName: "Airtel Money",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: true,
        rates: [
            { minAmount: 1, maxAmount: 1500, rate: 12 },
            { minAmount: 1501, maxAmount: 7000, rate: 18 },
            { minAmount: 7001, maxAmount: 20000, rate: 25 }
        ]
    },
    {
        id: "3",
        serviceName: "Equity Bank EazzyPay",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: true,
        rates: [
            { minAmount: 1, maxAmount: 3000, rate: 8 },
            { minAmount: 3001, maxAmount: 8000, rate: 12 },
            { minAmount: 8001, maxAmount: 15000, rate: 18 }
        ]
    },
    {
        id: "4",
        serviceName: "T-Kash",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: false,
        rates: [
            { minAmount: 1, maxAmount: 1000, rate: 9 },
            { minAmount: 1001, maxAmount: 6000, rate: 14 }
        ]
    },
    {
        id: "5",
        serviceName: "PesaLink",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: true,
        rates: [
            { minAmount: 1, maxAmount: 2000, rate: 5 },
            { minAmount: 2001, maxAmount: 10000, rate: 10 }
        ]
    },
    {
        id: "6",
        serviceName: "KCB M-Pesa",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: true,
        rates: [
            { minAmount: 1, maxAmount: 2500, rate: 6 },
            { minAmount: 2501, maxAmount: 12000, rate: 11 }
        ]
    },
    {
        id: "7",
        serviceName: "PayPal",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: false,
        rates: [
            { minAmount: 1, maxAmount: 5000, rate: 20 },
            { minAmount: 5001, maxAmount: 20000, rate: 30 }
        ]
    },
    {
        id: "8",
        serviceName: "Stripe",
        countryName: "Kenya",
        currencyCode: "KES",
        isActive: true,
        rates: [
            { minAmount: 1, maxAmount: 3000, rate: 7 },
            { minAmount: 3001, maxAmount: 15000, rate: 13 }
        ]
    }
];
