import { useEffect, useState } from "react";
import { GrCurrency } from "react-icons/gr";
import { dummyCurrencies } from "../../data";
import { Currency, UIPaymentActions } from "../../types";
import { Modal } from "../common";
import { SearchBar } from "../common/search-bar";
import { CardListItem } from "./card-list-items";
import { FullLengthBtn } from "./full-length-btn";
import { PaymentOptionsListHeader } from "./payment-options-list-header";
import { SupportedCurrencyItemActions } from "./supported-currency-item-actions";
import { SupportedCurrencyDetails } from "./supported-currency-detail";

export function SupportedCurrenciesList() {
  const [modalContent, setModalContent] = useState<UIPaymentActions | undefined>(undefined);
  const [title, setTitle] = useState("");
  const [toggleMsg, setToggleMsg] = useState("");
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | undefined>(undefined);
  const [filteredCurrencies, setFilteredCurrencies] = useState<Currency[]>(dummyCurrencies);

  const leftIcon = <GrCurrency className="text-blue-500 text-2xl mr-4" />;

  const onClose = () => {
    setModalContent(undefined);
    setSelectedCurrency(undefined);
  };
  const onAddNewCurrency = () => {
    setTitle("Add new currency");
    setModalContent("form");
  };

  useEffect(() => {
    if (!!searchText) setFilteredCurrencies(filterCurrencies(dummyCurrencies, searchText));
  }, [searchText]);

  let contentBody = <></>;
  if (modalContent === "details") contentBody = selectedCurrency ? <SupportedCurrencyDetails currency={selectedCurrency} /> : <></>;
  else if (modalContent === "form") contentBody = <></>;
  else if (modalContent === "toggle") contentBody = <p>{toggleMsg}</p>;

  const results = !!searchText ? filteredCurrencies : dummyCurrencies;

  return (
    <>
      <div className="rounded-lg shadow w-11/12 h-5/6 flex flex-col overflow-hidden bg-white bg-opacity-80 backdrop-blur-md pt-4">
        {/* Card Title */}
        <PaymentOptionsListHeader label="Supported currencies" setShowSearch={setShowSearch} showSearch={showSearch} />

        {showSearch && (
          <div className="flex w-full justify-end pr-12">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
          </div>
        )}

        {/* Scrollable list of dummy cards */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4 custom-scrollbar px-8">
          {results.map(currency => (
            <CardListItem
              key={currency.id}
              id={currency.id}
              leftIcon={leftIcon}
              title={currency.currencyName}
              actions={
                <SupportedCurrencyItemActions
                  currency={currency}
                  setModalContent={setModalContent}
                  setTitle={setTitle}
                  setToggleMsg={setToggleMsg}
                  setSelectedCurrency={setSelectedCurrency}
                />
              }
              subTitle={currency.currencyCode}
            />
          ))}
        </div>

        {/* Button at the bottom */}
        <FullLengthBtn label="Add new supported Currency" onClick={onAddNewCurrency} />
      </div>
      {!!modalContent && (
        <Modal
          title={title}
          cancelText={modalContent === "details" ? "Close" : "Cancel"}
          proceedText={modalContent === "details" ? "" : "Confirm"}
          contentBody={contentBody}
          onProceed={() => {}}
          onClose={onClose}
          isVisible={!!modalContent}
        />
      )}
    </>
  );
}

function filterCurrencies(currencies: Currency[], searchText: string): Currency[] {
  const lowerCaseSearchText = searchText.toLowerCase().trim();

  return currencies.filter(currency => currency.currencyName.toLowerCase().startsWith(lowerCaseSearchText));
}
