import { getDataFromDummySource } from "../model/acc-model";
import { RowResults, TableFilter } from "../types";

// component to manage data fetching
export async function fetchData(
  pageIndex: number, // Current page index
  pageSize: number, // Number of records per page
  filter?: TableFilter // Filter string
): Promise<RowResults> {
  return getDataFromDummySource(pageIndex, pageSize, filter);
}

// function to handle updating the filter
export async function updateFilter(pageIndex: number, pageSize: number, filter: TableFilter): Promise<RowResults> {
  return await fetchData(pageIndex, pageSize, filter);
}

// function to handle pagination
export async function updatePage(pageIndex: number, pageSize: number, filter?: TableFilter): Promise<RowResults> {
  return await fetchData(pageIndex, pageSize, filter);
}
