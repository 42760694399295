import { useEffect, useState } from "react";
import { fetchData, updateFilter } from "../controller/fetch-data"; // Import your data functions
import { RowData, data as total } from "../utils";
import { BsFilterSquare } from "react-icons/bs";
import { FilterCondition, FilterTransactionType, FilterType, TableFilter, TransactionTableFilter } from "../types";
import { fetchTransactions } from "../controller";

export function TransactionFilterButton({ onClick }: { onClick: (item: TransactionTableFilter) => void }) {
  const [showFilters, setShowFilters] = useState(false);
  const [filterType, setFilterType] = useState<FilterTransactionType>("status"); // "status" or "date"
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dateCondition, setDateCondition] = useState<FilterCondition>("on"); // "on", "before", "after"
  const [filterDate, setFilterDate] = useState("");

  // Toggle filter section visibility
  const toggleFilters = () => setShowFilters(!showFilters);

  const onSubmit = () => {
    if (filterType === "status" && selectedStatus) {
      onClick({ filter: selectedStatus, type: filterType });
    } else if (filterType === "date" && filterDate) {
      console.log(filterDate);
      onClick({ filter: filterDate, type: filterType, condition: dateCondition });
    } else {
      // show an alert
    }
  };

  return (
    <div className="flex flex-col m-4 items-start">
      {/* Filter Button */}
      <button
        onClick={toggleFilters}
        className="flex ml-2 flex-row justify-between items-center gap-4 space-x-3 bg-gray-200 text-md font-semibold px-4 py-2 rounded-md shadow hover:bg-gray-300 transition duration-300"
      >
        Filter
        <BsFilterSquare className="text-blue-400" />
      </button>

      {/* Filter Section */}
      {showFilters && (
        <div className="flex flex-row ml-2 space-x-4 my-2 p-4 bg-white rounded-md shadow-md">
          <div>
            <label className="text-md font-semibold text-black">
              Filter By:
              <select
                value={filterType}
                onChange={e => setFilterType(e.target.value as FilterTransactionType)}
                className="border-b border-gray-300 font-medium focus:outline-none focus:border-blue-400 text-md"
              >
                <option value="">Select Filter</option>
                <option value="status">Status</option>
                <option value="date">Date</option>
              </select>
            </label>
          </div>

          {/* Filter by Bank */}
          {filterType === "status" && (
            <div>
              <label className="text-md font-semibold text-black">
                currency:
                <select
                  value={selectedStatus}
                  onChange={e => setSelectedStatus(e.target.value)}
                  className="border-b border-gray-300 focus:outline-none font-medium focus:border-blue-50 text-md"
                >
                  <option value="">Status</option>
                  <option value="pending" className="text-yellow-500">
                    Pending
                  </option>
                  <option value="processing" className="text-blue-500">
                    Processing
                  </option>
                  <option value="failed" className="text-red-500">
                    Failed
                  </option>
                  <option value="success" className="text-green-500">
                    Success
                  </option>
                </select>
              </label>
            </div>
          )}

          {/* Filter by Date */}
          {filterType === "date" && (
            <div className="flex flex-row items-center space-x-2">
              <label className="text-md font-semibold text-black">
                Date Condition:
                <select
                  value={dateCondition}
                  onChange={e => setDateCondition(e.target.value as FilterCondition)}
                  className="border-b border-gray-300 focus:outline-none font-medium focus:border-blue-400 text-md"
                >
                  <option value="">Select Condition</option>
                  <option value="on">On</option>
                  <option value="before">Before</option>
                  <option value="after">After</option>
                </select>
              </label>
              <input
                type="date"
                value={filterDate}
                onChange={e => setFilterDate(e.target.value)}
                className="border-b border-gray-300 focus:outline-none font-normal focus:border-blue-400 text-sm"
              />
            </div>
          )}

          {/* Apply Filters Button */}
          <button
            onClick={onSubmit}
            className="bg-green-300 text-white font-semibold py-1 px-1 rounded-md shadow hover:bg-green-500 transition duration-300"
          >
            submit
          </button>
        </div>
      )}
    </div>
  );
}
