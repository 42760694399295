import { PaymentOptionValues } from "../../types";
import { PaymentOptionListItem } from "./payment-option-list-item";

interface PaymentOptionsListProps {
  selectedOption: PaymentOptionValues;
  setSelectedOption: React.Dispatch<React.SetStateAction<PaymentOptionValues>>;
}

const listItems: { itemValue: PaymentOptionValues; label: string }[] = [
  { itemValue: "supportedBanks", label: "Supported banks" },
  { itemValue: "supportedCurrencies", label: "Supported Currencies" },
  { itemValue: "supportedPaymentMethods", label: "Supported Payment Methods" },
];

export function PaymentOptionsList({ selectedOption, setSelectedOption }: PaymentOptionsListProps) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value as PaymentOptionValues);
  };

  return (
    <div>
      <div className="text-2xl mb-4">Payment options</div>
      <div className="space-y-4">
        {listItems.map(({ itemValue, label }) => (
          <PaymentOptionListItem
            key={itemValue}
            itemValue={itemValue}
            label={label}
            onChange={onChange}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        ))}
      </div>
    </div>
  );
}
