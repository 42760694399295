import { RowResults, TableFilter } from "../types";
import { data, RowData } from "../utils";

//component for data fetching
export const getDataFromDummySource = (pageIndex: number, pageSize: number, filterOptions?: TableFilter): RowResults => {
  let rows: RowData[] = [];
  if (filterOptions) {
    if (filterOptions.type === "currency") {
      // Filter by currency
      rows = data.filter(row => row.currency.toLowerCase().includes(filterOptions.filter.toLowerCase()));
      console.log("currency rows ........", rows);
    } else if (filterOptions.type === "date") {
      // Filter by date
      const filterDate = new Date(filterOptions.filter);
      rows = data.filter(row => {
        const rowDate = new Date(row.createdOn);
        if (filterOptions.condition === "on") {
          return rowDate.toDateString() === filterDate.toDateString();
        } else if (filterOptions.condition === "before") {
          return rowDate < filterDate;
        } else if (filterOptions.condition === "after") {
          return rowDate > filterDate;
        }
      });
    }
  }

  console.log("filtering", rows);

  // Paginate the data based on pageIndex and pageSize
  const startIndex = pageIndex * pageSize;

  console.log("my rowss.....", rows);
  // if filter is applied slice the rows otherwise slice the data
  rows = filterOptions ? rows.slice(startIndex, startIndex + pageSize) : data.slice(startIndex, startIndex + pageSize);
  console.log("final rows", rows);
  return { rows, totalRecords: filterOptions ? rows.length : data.length };
};
