import { BsBank } from "react-icons/bs";
import { CiCircleCheck, CiGlobe } from "react-icons/ci";
import { IoIosBarcode } from "react-icons/io";
import { PaymentMethod } from "../../types";
import { DetailsListItem } from "./details-list-item";
import { PaymentMethodRatesListItem } from "./payment-method-rates-list-item";

export function SupportedPaymentMethodsDetails({ paymentMethod }: { paymentMethod: PaymentMethod }) {
  const { countryName, currencyCode, isActive, serviceName } = paymentMethod;
  const activeStatus = isActive ? "Active" : "Inactive";

  return (
    <div>
      <DetailsListItem icon={<BsBank className="text-blue-500 text-2xl" />} label="Bank name" value={serviceName} />
      <DetailsListItem icon={<IoIosBarcode className="text-blue-500 text-2xl" />} label="Currency code" value={currencyCode} />
      <DetailsListItem icon={<CiGlobe className="text-blue-500 text-2xl" />} label="Country name" value={countryName} />
      <DetailsListItem icon={<CiCircleCheck className="text-blue-500 text-2xl" />} label="Status" value={activeStatus} />
      <PaymentMethodRatesListItem rates={paymentMethod.rates} />
    </div>
  );
}
