import "../index.css";

interface ScheduleCallInterface {
  children: React.ReactNode; // any react node
  className?: string;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  icon?: React.ReactNode;
  type?: "button" | "submit" | "reset"; // Specify button types
}

export function Button({
  children,
  className = "",
  onClick,
  onMouseEnter,
  onMouseLeave,
  icon,
  type = "button",
}: ScheduleCallInterface) {
  return (
    <button
      className={`bg-secondary text-black py-2 px-2 lg:px-4 lg:py-3 rounded-lg text-sm sm:text-base md:text-lg  hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 w-32 sm:w-36  lg:w-auto flex items-center justify-center ${className}`}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={type}
    >
      {icon && <div className=" mr-1 lg:mr-2 md:text-sm">{icon}</div>}
      {children}
    </button>
  );
}
