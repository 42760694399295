import { HiOutlineEye } from "react-icons/hi";
import { Bank, UIPaymentActions } from "../../types";

interface SupportedBankItemActionsProps {
  bank: Bank;
  setModalContent: React.Dispatch<React.SetStateAction<UIPaymentActions | undefined>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setToggleMsg: React.Dispatch<React.SetStateAction<string>>;
  setSelectedBank: React.Dispatch<React.SetStateAction<Bank | undefined>>;
}

export function SupportedBankItemActions(props: SupportedBankItemActionsProps) {
  const { bank, setModalContent, setTitle, setToggleMsg, setSelectedBank } = props;

  const onViewDetails = () => {
    setSelectedBank(bank);
    setTitle(`${bank.bankName} bank details`);
    setModalContent("details");
  };

  const onToggleStatus = () => {
    setTitle(bank.isActive ? `Deactivate ${bank.bankName} bank` : `Activate ${bank.bankName} bank`);
    setToggleMsg(`Are you sure you want to ${bank.isActive ? "deactivate" : "activate"} this bank ?`);
    setModalContent("toggle");
  };

  return (
    <>
      <button className="p-2 text-blue-500 hover:text-blue-700" onClick={onViewDetails}>
        <HiOutlineEye className="text-2xl" />
      </button>
      <label className="relative inline-flex items-center cursor-pointer">
        <input type="checkbox" checked={bank.isActive} onChange={onToggleStatus} value="" className="sr-only peer" />
        <div className="w-9 h-5 bg-gray-200 hover:bg-gray-300 peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-600 hover:peer-checked:bg-blue-700"></div>
      </label>
    </>
  );
}
