import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useGlobalFilter, usePagination, useTable } from "react-table";
import { FilterButton, SearchButton } from "../components";
import { fetchData, updateFilter, updatePage } from "../controller/fetch-data";
import { RowDataNavigate, RowResults, TableFilter } from "../types";
import { RowData } from "../utils";
import { columns } from "../utils/total-acc";

export function AccviewTable(): JSX.Element {
  const [data, setData] = useState<RowData[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<TableFilter | undefined>(undefined);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState<number>(10); // Set page size to 10
  // Calculate total number of pages
  const [pageCount, setPageCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchData(pageIndex, pageSize, filter).then((fetchedData: RowResults) => {
      setData(fetchedData.rows);
      setPageCount(Math.ceil(fetchedData.totalRecords / pageSize));
      setLoading(false);
    });
  }, [pageIndex, pageSize, pageCount, filter]);

  const tableData = useMemo(() => data, [data]);

  const useTableOptions = { columns: columns, data: tableData, manualPagination: true, pageCount, initialState: { pageIndex, pageSize } };
  // React Table setup
  const table = useTable(useTableOptions, useGlobalFilter, usePagination); // Extend table with pagination functionalities
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table;
  const { pageOptions, setGlobalFilter, globalFilter, preGlobalFilteredRows } = table;

  // Handler for changing the filter
  const handleFilterChange = (selectedFilter: TableFilter) => {
    setFilter(selectedFilter); // Update local filter state
    console.log(selectedFilter);
    updateFilter(0, pageSize, selectedFilter).then(fetchedData => {
      setData(fetchedData.rows);
      setPageIndex(0); // Reset to page
      setPageCount(Math.ceil(fetchedData.totalRecords / pageSize));
    });
  };

  const canPreviousPage = pageIndex !== 0;
  const canNextPage = pageIndex + 1 < pageCount;

  // console.log(canPreviousPage);
  // console.log(canNextPage);

  // Handlers for pagination
  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
    updatePage(newPageIndex, pageSize, filter).then(fetchedData => {
      setData(fetchedData.rows);
    });
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    setPageIndex(0); // Reset to first page
    updatePage(0, newSize, filter).then(fetchedData => {
      setData(fetchedData.rows);
    });
  };
  const handleRowClick = (accNo: RowDataNavigate) => navigate(`/accounts/${accNo}`);

  return (
    <div className="">
      {/* Filter and Search Components */}
      <div className="flex justify-between items-center mb-4">
        <FilterButton onClick={handleFilterChange} />
        <SearchButton
          onChange={(e: ChangeEvent<HTMLInputElement>) => setGlobalFilter(e.target.value)}
          value={globalFilter}
          placeholder={`Search ${preGlobalFilteredRows.length} records...`}
        />
      </div>

      {/* Table Section */}
      <div className="overflow-x-auto">
        <table {...getTableProps()} className="table-auto w-full text-left whitespace-no-wrap">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="px-4 py-2 font-semibold text-gray-900 bg-blue-300">
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="hover:bg-gray-50 transition-colors">
                  {row.cells.map(cell => (
                    <td
                      {...cell.getCellProps()}
                      className="px-4 py-2 text-gray-700 cursor-pointer"
                      onClick={() => handleRowClick(row.original.ownersId)}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Pagination Section */}
      <div className="flex justify-between items-center my-2 p-2">
        {/* Rows Per Page */}
        <div className="flex items-center">
          <span className="mr-2">Rows per page:</span>
          <select value={pageSize} onChange={handlePageSizeChange} className="border border-gray-300 text-gray-700 p-1 rounded-md">
            {[10, 20, 30, 50].map(size => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
        </div>

        {/* Page Navigation */}
        <div className="flex items-center">
          <button
            onClick={() => handlePageChange(pageIndex - 1)}
            disabled={!canPreviousPage}
            className={`p-2 ${canPreviousPage ? "text-blue-500" : "text-gray-400"}`}
          >
            <GoChevronLeft size={24} />
          </button>
          <span className="mx-2">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            onClick={() => handlePageChange(pageIndex + 1)}
            disabled={!canNextPage}
            className={`p-2 ${canNextPage ? "text-blue-500" : "text-gray-400"}`}
          >
            <GoChevronRight size={24} />
          </button>
        </div>
      </div>
    </div>
  );
}
