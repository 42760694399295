import { Button } from "./button";
import { useNavigate } from "react-router-dom";

const HeroImage = "../images/heroImage.png";
const LogoImage = "../images/logo.png";

export function Landing() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/accounts");
  };
  return (
    <div className="lg:flex lg:flex-row md:items-center lg:justify-between bg-white text-center py-3 sm:flex sm:flex-col-reverse relative">
      {/* Logo Section */}
      <div className="absolute top-0 left-0 p-4 md:ml-10 ">
        <img src={LogoImage} alt="Logo" className="w-25 h-20 object-contain" />
      </div>

      <div className="md:w-1/2 md:pr-8 md:ml-10 md:pl-8 flex flex-col items-center">
        <div className="flex-1 text-center lg:text-left lg:pr-6 mb-6 lg:mb-0 flex flex-col justify-center">
          <h1 className="text-3xl md:whitespace-nowrap font-bold text-blue-950 md:text-4xl lg:text-4xl md:mb-4 font-poppins">
            Virtual wallet interface
          </h1>
          <p className="mt-4 text-lg text-gray-600 sm:max-w-3xl md:max-w-6xl lg:max-w-2xl  font-poppins">
            Streamline and optimize customer support with our comprehensive platform, manage inquiries, and provide seamless solutions—all
            in one place.
          </p>
        </div>
        <div className="mt-10 sm:flex sm:flex-col lg:flex-row lg:gap-6 md:justify-center items-center">
          <Button onClick={handleClick}>Signup with Beren Tech Limited</Button>
        </div>
      </div>

      <div className="mt-10 w-full lg:mt-0 lg:w-1/2 lg:mb-5 flex justify-center lg:justify-end md:mt-0">
        <img src={HeroImage} alt="Hero" className="w-full h-auto object-cover" />
      </div>
    </div>
  );
}
