import { useState } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { DownloadPDF } from "../components/downloadpdf-button";
import { TransactionsTable } from "../view/transaction-overview";

const LogoImage = "../images/logo.png";

export function TransactionsOverview() {
  const [showOperations, setShowOperations] = useState<boolean>(false);
  const [isToggle, setIsToggle] = useState(false);

  const handleClick = () => {
    setShowOperations(!showOperations);
    setIsToggle(!isToggle);
  };

  const handleDeposit = () => {
    // Handle deposit operation
    console.log("Deposit clicked");
  };

  const handleWithdrawal = () => {
    // Handle withdrawal operation
    console.log("Withdrawal clicked");
  };

  const handleDownloadPDF = () => {
    // Handle PDF download
    console.log("Download CSV clicked");
  };

  return (
    <div className="justify-between flex-col flex">
      <div className="flex flex-row justify-between">
        <div className="top-0 left-0 p-4 md:ml-2">
          <img src={LogoImage} alt="Logo" className="w-25 h-20 object-contain" />
        </div>
        <div className="flex flex-row gap-4 justify-center items-center mr-4">
          <DownloadPDF />
          <div className="relative">
            <button
              onClick={handleClick}
              className="bg-blue-500 flex items-center rounded-lg p-2 px-4 text-white font-poppins font-bold text-sm flex-row gap-3 hover:bg-blue-600"
            >
              Operations
              {isToggle ? <GoChevronUp size={25} /> : <GoChevronDown size={25} />}
            </button>
            {showOperations && (
              <div className="absolute right-0 mt-2 w-auto p-3 bg-white border rounded-md shadow-lg">
                <button onClick={handleDeposit} className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100">
                  Deposit
                </button>
                <button onClick={handleWithdrawal} className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100">
                  Withdrawal
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-3">
        <TransactionsTable />
      </div>
    </div>
  );
}
