import { PaymentOptionValues } from "../../types";

interface PaymentOptionListItemProps {
  label: string;
  itemValue: PaymentOptionValues;
  selectedOption: PaymentOptionValues;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
  setSelectedOption: React.Dispatch<React.SetStateAction<PaymentOptionValues>>;
}

export function PaymentOptionListItem(props: PaymentOptionListItemProps) {
  const { selectedOption, onChange, itemValue, setSelectedOption, label } = props;

  return (
    <div
      className={`border rounded-lg p-4 flex items-center bg-white bg-opacity-70 cursor-pointer transition-colors ${
        selectedOption === itemValue ? "border-blue-500" : "border-gray-300"
      }`}
      onClick={() => setSelectedOption(itemValue)}
    >
      <input
        type="radio"
        id={itemValue}
        name="paymentConfig"
        value={itemValue}
        checked={selectedOption === itemValue}
        onChange={onChange}
        className="form-radio h-5 w-5 text-blue-600 border-gray-300 mr-4 cursor-pointer"
      />
      <label htmlFor={itemValue} className="text-lg cursor-pointer">
        {label}
      </label>
    </div>
  );
}
