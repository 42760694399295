import { fetchTransactions } from "../controller";
import { Transactions, TransactionTableFilter } from "../types";
import { transactionData } from "../utils";

console.log(transactionData);
export async function getTransactionsByAccount(
  accNo: string,
  Page: number,
  pageSize: number,
  filterOptions?: TransactionTableFilter
): Promise<{ transactions: Transactions[]; totalRecords: number }> {
  let transactions: Transactions[] = transactionData[accNo] || [];
  if (filterOptions) {
    if (filterOptions.type === "status") {
      transactions = transactionData[accNo].filter(transaction => transaction.status === filterOptions.filter);
    } else if (filterOptions.type === "date") {
      const filterDate = new Date(filterOptions.filter);
      transactions = transactionData[accNo].filter(transaction => {
        const transactionDate = new Date(transaction.date);
        if (filterOptions.condition === "on") {
          return transactionDate.toDateString() === filterDate.toDateString();
        } else if (filterOptions.condition === "before") {
          return transactionDate < filterDate;
        } else if (filterOptions.condition === "after") {
          return transactionDate > filterDate;
        }
      });
    }
  }

  console.log("my transaction.....", transactions);

  const transactionDataArray = transactionData[accNo];
  console.log("transaction array...", transactionDataArray);
  const startIndex = Page * pageSize;
  transactions = filterOptions
    ? transactions.slice(startIndex, startIndex + pageSize)
    : transactionDataArray.slice(startIndex, startIndex + pageSize);

  return {
    transactions,
    totalRecords: filterOptions ? transactions.length : transactionDataArray.length,
  };
}
