import { Column } from "react-table";

export interface RowData {
  createdOn: string;
  ownersId: string;
  currency: string;
  transactions: number;
}

export const data = [
  { createdOn: "2023-09-01", ownersId: "User: John Doe", currency: "USD", transactions: 3 },
  { createdOn: "2023-08-22", ownersId: "RentHub Building: 123 Main St", currency: "EUR", transactions: 5 },
  { createdOn: "2023-07-15", ownersId: "User: Alice Smith", currency: "GBP", transactions: 2 },
  { createdOn: "2023-06-10", ownersId: "Company: Johnson Realty", currency: "AUD", transactions: 7 },
  { createdOn: "2023-05-08", ownersId: "User: Charlie Brown", currency: "CAD", transactions: 6 },
  { createdOn: "2023-04-12", ownersId: "RentHub Building: 456 Oak Ave", currency: "JPY", transactions: 4 },
  { createdOn: "2023-03-25", ownersId: "User: Ella Fitzgerald", currency: "INR", transactions: 3 },
  { createdOn: "2023-02-18", ownersId: "Company: Washington Holdings", currency: "ZAR", transactions: 8 },
  { createdOn: "2023-01-05", ownersId: "User: Hannah Davis", currency: "NZD", transactions: 2 },
  { createdOn: "2022-12-14", ownersId: "RentHub Building: 789 Pine St", currency: "CNY", transactions: 5 },
  { createdOn: "2022-11-02", ownersId: "User: Jessica Alba", currency: "MXN", transactions: 3 },
  { createdOn: "2022-10-09", ownersId: "User: Kevin Hart", currency: "CHF", transactions: 6 },
  { createdOn: "2022-09-23", ownersId: "RentHub Building: 321 Maple Dr", currency: "HKD", transactions: 4 },
  { createdOn: "2022-08-11", ownersId: "User: Mona Lisa", currency: "SGD", transactions: 7 },
  { createdOn: "2022-07-19", ownersId: "Company: Simone Estates", currency: "KRW", transactions: 2 },
  { createdOn: "2022-06-28", ownersId: "User: Oscar Wilde", currency: "SEK", transactions: 8 },
  { createdOn: "2022-05-15", ownersId: "RentHub Building: 654 Cedar Blvd", currency: "BRL", transactions: 5 },
  { createdOn: "2022-04-06", ownersId: "User: Quincy Jones", currency: "ARS", transactions: 6 },
  { createdOn: "2022-03-21", ownersId: "User: Rosa Parks", currency: "RUB", transactions: 3 },
  { createdOn: "2022-02-10", ownersId: "Company: Jobs Innovators", currency: "PLN", transactions: 4 },
  { createdOn: "2022-01-30", ownersId: "User: Taylor Swift", currency: "NOK", transactions: 7 },
];

// Table Columns
export const columns: Column<RowData>[] = [
  { Header: "Created On", accessor: "createdOn" },
  { Header: "Owner's ID", accessor: "ownersId" },
  { Header: "Currency", accessor: "currency" },
  { Header: "Transactions", accessor: "transactions" },
];
