import { FiSearch } from "react-icons/fi";
import { LuSearchX } from "react-icons/lu";

interface PaymentOptionsListHeaderProps {
  label: string;
  showSearch: boolean;
  setShowSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PaymentOptionsListHeader({ showSearch, setShowSearch, label }: PaymentOptionsListHeaderProps) {
  return (
    <div className="m-4 px-4 flex flex-row justify-between items-center">
      <div className="text-2xl">{label}</div>
      <button className={`mr-4  ${showSearch ? "hover:text-red-700" : "hover:text-blue-700"}`} onClick={() => setShowSearch(!showSearch)}>
        {showSearch ? <LuSearchX className="text-2xl" /> : <FiSearch className="text-2xl" />}
      </button>
    </div>
  );
}
