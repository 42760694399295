import React from "react";

interface ListItemProps {
  label: string;
  value: string | number;
  icon: React.ReactNode;
}

export function DetailsListItem({ label, value, icon }: ListItemProps) {
  return (
    <div className="flex items-center justify-between p-4 bg-white shadow rounded-md">
      {/* Icon on the left */}
      <div className="flex items-center space-x-3">
        {/* Icon */}
        <div className="text-gray-500">{icon}</div>

        {/* Label */}
        <span className="text-gray-700 font-normal">{label}</span>
      </div>

      {/* Value on the right */}
      <span className="text-gray-950 font-normal">{value}</span>
    </div>
  );
}
