// controllers/transactionController.ts
import { getTransactionsByAccount } from "../model/transaction-model";
import { FetchTransactionsResult, Transactions, TransactionTableFilter } from "../types";

export async function fetchTransactions(
  accNo: string,
  page: number,
  pageSize: number,
  filterOptions?: TransactionTableFilter
): Promise<FetchTransactionsResult> {
  const { transactions, totalRecords } = await getTransactionsByAccount(accNo, page, pageSize, filterOptions);
  const totalBalance = transactions.reduce((total, transaction) => total + (transaction.credit - transaction.debit), 0);
  return { transactions, totalBalance, totalRecords };
}
