import { HiOutlineTicket } from "react-icons/hi2";
import { PaymentMethodRate } from "../../types";

export function PaymentMethodRatesListItem({ rates }: { rates: PaymentMethodRate[] }) {
  return (
    <div className="flex items-start justify-between p-4 bg-white shadow rounded-md">
      {/* Icon and Label on the left */}
      <div className="flex space-x-3">
        <div className="text-gray-500">
          <HiOutlineTicket className="text-blue-500 text-2xl" />
        </div>
        <span className="text-gray-700 font-normal">Rates</span>
      </div>

      {/* Rates on the right */}
      <div className="text-right space-y-2">
        {rates.map((rate, index) => (
          <div key={index} className="text-gray-950 font-normal">
            <span>
              {rate.minAmount} - {rate.maxAmount}
            </span>
            <span className="ml-2 text-sm text-gray-600">(KES {rate.rate})</span>
          </div>
        ))}
      </div>
    </div>
  );
}
